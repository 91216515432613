import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import {
  GuidanceStampRally,
  GuidanceStampRallyGiftRedemption,
  GuidanceStampRallyRedemption,
} from 'models/guidanceStampRally';
import { createAction } from 'ducks/actionHelpers';
import { REDEEM_STAMP_RALLY_SUCCESS, redeemStampRallySuccess } from 'ducks/client/stampRallies';
import {
  REDEEM_STAMP_RALLY_GIFT_SUCCESS,
  redeemStampRallyGiftSuccess,
} from 'ducks/client/stampRallyGifts';

const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST';
const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS';
const FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE = 'FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE';

const fetchDigitalGuidanceStampRallyRequest = () =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST);
const fetchDigitalGuidanceStampRallySuccess = (response: GuidanceStampRally | null) =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS, response);
const fetchDigitalGuidanceStampRallyFailure = (err: string) =>
  createAction(FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE, err);

export const fetchDigitalGuidanceStampRally = (
  apiKey: string,
  stampRallyId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidanceStampRallyRequest());

  const path = `${config.apiUrl}/guidancestamprallies/${stampRallyId}`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalGuidanceStampRallySuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidanceStampRallyFailure(err.message));
    });
};

export const fetchDigitalGuidanceStampRallyForReservation = (
  apiKey: string,
  stampRallyId: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidanceStampRallyRequest());

  const path = `${config.apiUrl}/reservations/${reservationId}/guidancestamprallies`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = (response.data as GuidanceStampRally[])?.find(
        (stampRally) => stampRally.id === stampRallyId
      );
      dispatch(fetchDigitalGuidanceStampRallySuccess(payload || null));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidanceStampRallyFailure(err.message));
    });
};

export const fetchDigitalGuidanceStampRallyForNonReservation = (
  apiKey: string,
  stampRallyId: string,
  trackingId: string,
  supplierId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidanceStampRallyRequest());

  const path = `${config.apiUrl}/${trackingId}/guidancestamprallies`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
      params: {
        supplier_id: supplierId,
      },
    })
    .then((response) => {
      const payload = (response.data as GuidanceStampRally[])?.find(
        (stampRally) => stampRally.id === stampRallyId
      );
      dispatch(fetchDigitalGuidanceStampRallySuccess(payload || null));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidanceStampRallyFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalGuidanceStampRallyRequest>
  | ReturnType<typeof fetchDigitalGuidanceStampRallySuccess>
  | ReturnType<typeof fetchDigitalGuidanceStampRallyFailure>
  | ReturnType<typeof redeemStampRallySuccess>
  | ReturnType<typeof redeemStampRallyGiftSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return action.payload;
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST:
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return '';
    default:
      return state;
  }
};

const stampRally = (
  state: GuidanceStampRally | null = null,
  action: Action
): GuidanceStampRally | null => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return action.payload;
    case FETCH_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return null;
    case REDEEM_STAMP_RALLY_SUCCESS: {
      if (state?.id === action.payload.stamp_rally_id) {
        return {
          ...state,
          stamp_rally_redemptions: [
            ...(state?.stamp_rally_redemptions ?? []),
            action.payload as GuidanceStampRallyRedemption,
          ],
        };
      }

      return state;
    }
    case REDEEM_STAMP_RALLY_GIFT_SUCCESS: {
      if (state?.id === action.payload.stamp_rally_id) {
        return {
          ...state,
          stamp_rally_gift_redemptions: [
            ...(state?.stamp_rally_gift_redemptions ?? []),
            action.payload as GuidanceStampRallyGiftRedemption,
          ],
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export interface DigitalGuidanceStampRallyState {
  error: ReturnType<typeof error>;
  stampRally: ReturnType<typeof stampRally>;
}

export default combineReducers({
  error,
  stampRally,
});
